import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link, Box } from '@material-ui/core';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';

import { CUSTOMER_APP_URL } from 'app/config/externalLinks';
import { EVENTS } from 'app/lib/analytics/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import JumpTo from 'components/modules/Shared/JumpTo';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';

import LeadCaptureDialog from '../LeadCapture/Dialog';
import PartnerReferAFriend from './Banners/PartnerReferAFriend';
import SmartMeter from './Banners/SmartMeter';
import Renewals from './Banners/Renewals';
import PartnerSignUp from './Banners/PartnerSignUp';
import ReferAFriend from './Banners/ReferAFriend';
import Header from './Header';
import useStyles from './styles';

const ThankYou = ({
  name,
  isPartnerLead,
  customerAccountNumber,
  showSmartBanner,
  leadCaptureDialogOpen,
  setLeadCaptureDialog,
}) => {
  const classes = useStyles();
  const trackOnce = useTrackOnce();
  const { variant } = useFeature(features.ENABLE_SMART_METER_BANNER);
  const smartMeterBannerEnabled =
    variant === variations[features.ENABLE_SMART_METER_BANNER].ON;
  const { variant: leadCaptureVariant } = useFeature(
    features.ENABLE_LEAD_CAPTURE
  );
  const leadCaptureEnabled =
    leadCaptureVariant === variations[features.ENABLE_LEAD_CAPTURE].ON;

  return (
    <JumpTo id="page-header" autoScroll>
      <Header name={name} />
      <LeadCaptureDialog
        open={leadCaptureDialogOpen}
        onClose={() => setLeadCaptureDialog(false)}
      />
      <SectionCentered
        newLayout
        sectionCenteredClasses={{
          root: classes.nextSectionWrapper,
          container: classes.section,
        }}
      >
        <Typography className={classes.accountNumber}>
          Account number: <strong>{customerAccountNumber}</strong>
        </Typography>
        <Typography variant="h2" className={classes.subTitle}>
          What happens next?
        </Typography>
        <ul className={classes.nextList}>
          <Typography component="li" className={classes.nextListItem}>
            <FlagPointIcon />
            Verify your email address by clicking the link in the email we’ve
            just sent you, so you can access your account online and in the UW
            app. Please check your spam or junk folder for this.
          </Typography>
          <Typography component="li" className={classes.nextListItem}>
            <FlagPointIcon />
            <span>
              <Link
                underline="none"
                href={CUSTOMER_APP_URL}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                onClick={() => trackOnce(EVENTS.THANKYOU_APP_DOWNLOAD_CLICKED)}
              >
                Download the UW app
              </Link>{' '}
              to track your switch live and manage your chosen services.
            </span>
          </Typography>
        </ul>
        <Box mt={3} className={classes.referralBanners}>
          {isPartnerLead && <PartnerSignUp />}
          {leadCaptureEnabled && isPartnerLead && <PartnerReferAFriend />}
          {!isPartnerLead && <ReferAFriend />}
        </Box>
      </SectionCentered>
      <SectionCentered
        newLayout
        sectionCenteredClasses={{
          container: classes.section,
        }}
      >
        <Typography variant="h2" className={classes.subTitle}>
          Maximise your savings
        </Typography>
        <Renewals />
        {smartMeterBannerEnabled && showSmartBanner && <SmartMeter />}
      </SectionCentered>
    </JumpTo>
  );
};

ThankYou.propTypes = {
  name: PropTypes.string.isRequired,
  isPartnerLead: PropTypes.bool.isRequired,
  customerAccountNumber: PropTypes.string.isRequired,
  showSmartBanner: PropTypes.bool.isRequired,
  leadCaptureDialogOpen: PropTypes.bool.isRequired,
  setLeadCaptureDialog: PropTypes.func.isRequired,
};

export default ThankYou;
